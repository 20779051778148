export default [
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'baseCurrency',
    },
    cell: {
      type: 'text',
      value: (e) => e.baseCurrency,
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'quoteCurrency',
    },
    cell: {
      type: 'text',
      value: (e) => e.quoteCurrency,
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'exchanger',
    },
    cell: {
      type: 'text',
      value: (e) => e.exchanger,
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'rate',
    },
    cell: {
      type: 'text',
      value: (e) => e.rate,
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'coefficient',
    },
    cell: {
      type: 'text',
      value: (e) => e.coefficient,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
